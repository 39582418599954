.video-lecture-view .video-lecture-content {
    width: 100%;
    max-height: 80vh;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 45px 10px;
}
.video-lecture-view .close-modal-btn {
    top: 25px;
    right: 10px;
}
.video-lecture-view .close-modal-btn a {
    color: #fff;
    text-decoration: none;
}
.video-lecture-view .video-react {
    margin: auto;
    display: flex;
}
.video-lecture-view .video-react button {
    margin: auto;
    position: relative;
    top: auto;
    left: auto;
}
.video-react {
    height: 2000px;
}