.modalForm {
    padding: 30px;
}
.modalFormBody {
    margin-top: 40px;
    padding: 0 25px;
}
.modalFormFooter {
    padding: 0 25px 15px;
    text-align: right;
}