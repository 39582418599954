.backToTop {
    position: fixed;
    z-index: 999;
    right: 0.75rem;
    bottom: 0.75rem;
    display: none;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 1.875rem;
    background: #00bfd8 url("../../../../public/images/up-arrow.png") no-repeat center 47%;
    background-size: 1.125rem 1.125rem;
    text-indent: -9999px;
    transition: opacity .5s;
    opacity: 0;
    cursor: pointer;
}
.backToTop:hover {
    background-color: #00a7bd;
}
.visible {
    display: block;
    opacity: 1;
}
.visible.transparent {
    opacity: 0;
}