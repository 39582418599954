.container {
    position: fixed;
    top: 70px;
    right: 0;
    margin: 0 20px;
    z-index: 1032;
}
.notificationMessage {
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .4rem;
    text-align: justify;
    transition: all 1s ease;
}
.notificationMessage.transparent {
    opacity: 0;
}
.notificationMessage.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.notificationMessage.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}
.notificationMessage.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}
.notificationMessage.error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.closeButton {
    float: right;
    font-size: 30px;
    margin: 0 0 13px 13px;
    cursor: default;
}