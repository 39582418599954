.cards-1,
.cards-2 {
    padding-top: 0;
}
.tariff-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.tariff-list .card .card-heading-wrapper {
    display: flex;
}
.tariff-list .card .card-heading {
    width: 100%;
    margin: auto;
}
.card.highlighted {
    animation: blinking-shadow 1500ms infinite;
}
.cards-2 .card .price {
    padding: .875rem 0;
    height: 120px;
    display: flex;
    flex-direction: column;
}
.cards-2 .card .price > * {
    margin: auto;
}
.price .value-old {
    text-decoration: line-through;
    color: #999;
    font-size: 1.7rem;
}
.marked {
    color: #00bfd8;
    font-weight: bold;
}
.blinking:not(:hover) {
    animation: blinking 1500ms infinite;
}
.card-responsive {
    margin: 0 auto 4.5rem auto;
    padding: 3.25rem 2rem 2rem 2rem;
    border: 1px solid #c4d8dc;
    border-radius: 0.5rem;
}
.cards-1 .card-responsive {
    display: flex;
}
.cards-1 .card-responsive > div {
    margin: auto;
}
.card-stats-note {
    color: var(--primary-color);
    font: 700 1.15rem/1.875rem "Raleway", sans-serif;
}
.card-stats {
    color: var(--primary-color);
    font-size: 3.7rem;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 1.15rem;
    line-height: 4rem;
}
p.cb-highlighted {
    color: #393939;
    font: 700 1.15rem/1.875rem "Raleway", sans-serif;
}
.slider-2 {
    padding-top: 1.5rem;
}
.slider-2 h2 {
    font-size: 2.2rem;
}
@-webkit-keyframes blinking {
    0% {
        background-color: #00dff8;
    }
    100% {
        background-color: #00bfd8;
    }
}
@-moz-keyframes blinking {
    0% {
        background-color: #00dff8;
    }
    100% {
        background-color: #00bfd8;
    }
}
@-o-keyframes blinking {
    0% {
        background-color: #00dff8;
    }
    100% {
        background-color: #00bfd8;
    }
}
@keyframes blinking {
    0% {
        background-color: #00dff8;
    }
    100% {
        background-color: #00bfd8;
    }
}
@-webkit-keyframes blinking {
    0% {
        box-shadow: 0 0 0 #34e29a;
    }
    100% {
        box-shadow: 0 0 25px #34e29a;
    }
}
@-moz-keyframes blinking {
    0% {
        box-shadow: 0 0 0 #34e29a;
    }
    100% {
        box-shadow: 0 0 25px #34e29a;
    }
}
@-o-keyframes blinking {
    0% {
        box-shadow: 0 0 0 #34e29a;
    }
    100% {
        box-shadow: 0 0 25px #34e29a;
    }
}
@keyframes blinking-shadow {
    0% {
        box-shadow: 0 0 0 #34e29a;
    }
    100% {
        box-shadow: 0 0 25px #34e29a;
    }
}

@media (min-width: 320px) {
    .pricing .card.row-3 {
        width: 19.5rem;
    }
}
@media (max-width: 480px) {
    .card-stats {
        font-size: 3.1rem;
    }
}
@media (min-width: 992px) {
    .cards-wrapper {
        display: flex;
    }
    .card-responsive {
        width: 21rem;
    }
    .pricing .card {
        width: 24.5rem;
    }
    .pricing .card.row-3 {
        width: 16.5rem;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .card-stats {
        font-size: 3.2rem;
    }
}
@media (min-width: 1200px) {
    .pricing .card.row-3 {
        width: 19.5rem;
    }
}
@media (min-width: 992px) and (max-width: 1999px) {
    .cards-2 .card .card-body {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
    }
    .cards-2 .card .value {
        font-size: 3rem;
    }
}
@media (min-width: 767px) {
    .tariff-list .card .card-heading-wrapper {
        min-height: 126px;
    }
}
@media (max-width: 1200px) {
    .cards-2 .card .prefix,
    .cards-2 .card .currency {
        font-size: 1.2rem;
    }
}