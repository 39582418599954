.zno-materials-page .zno-test .test-timer {
    text-align: center;
    min-height: 22px;
    font-weight: bold;
}
.zno-materials-page .zno-test .test-timer span {
    padding: 5px;
    border-radius: 5px;
}
.zno-test .test-timer span.alert-success {
    color: #00bf00;
}
.zno-test .test-timer span.alert-warning {
    color: #ff7700;
}
.zno-test .test-timer span.alert-danger {
    color: #f00;
}
.zno-materials-page .zno-test .question-list .list-element {
    box-shadow: 3px 3px 10px #DFF7FB;
    padding: 3px;
    width: 35px;
}
.zno-materials-page .question-list .list-element-wrapper {
    margin: auto;
}
.question-list .list-element.is-answered:not(.current) {
    background-color: #ddd;
}
.zno-materials-page .question-wrapper {
    padding: 20px;
    background-color: rgba(255, 255, 255, .7);
    border-radius: 10px;
}
.answer-variants-wrapper {
    display: flex;
}
.answer-variants-wrapper .col-left,
.answer-variants-wrapper .col-right {
    width: 50%;
}
.answer-variants {
    margin-top: 1.5rem;
}
.answer-variants li {
    margin-bottom: 2px;
}
.answer-variants img {
    max-height: 270px;
}
.answer-variant-label {
    display: inline-block;
    width: 28px;
    padding: 3px 9px;
    background-color: #00bfd8;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    line-height: 1.375rem;
}
.answer-variants-select-title {
    padding: 20px 20px 10px;
    font-weight: bold;
}
.answer-variants-select {
    line-height: 0;
}
.answer-variants-select-wrapper {
    display: flex;
}
.answer-variants-select-wrapper .answer-variants-input-label {
    font-weight: bold;
}
.answer-variants-select-wrapper .answer-variants-input-label:not(:first-child) {
    margin-left: 10px;
}
.answer-variants-select-wrapper .warning {
    margin-left: 20px;
}
.answer-select {
    margin-top: 1rem;
}
.answer-selection {
    width: 28px;
    height: 28px;
    border: 1px solid #00bfd8;
    border-radius: 5px;
    margin: 0;
}
.answer-selection.is-readonly {
    border-color: #777;
}
.answer-selection.is-readonly.is-correct {
    border-color: #0f0;
    background-color: rgba(0, 255, 0, .2);
}
.answer-selection.is-readonly.is-incorrect {
    border-color: #f00;
    background-color: rgba(255, 0, 0, .2);
}
.answer-selection input {
    position: absolute;
    visibility: hidden;
}
.answer-selection span {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.answer-selection span:before, .answer-selection span:after {
    position: absolute;
    left: 12px;
    content: ' ';
    height: 26px;
    width: 2px;
    background-color: #00bfd8;
}
.answer-selection.is-incorrect span:before, .answer-selection.is-incorrect span:after {
    background-color: #f00;
}
.answer-selection.is-correct span:before, .answer-selection.is-correct span:after {
    background-color: #0f0;
}
.answer-selection span:before {
    transform: rotate(45deg);
}
.answer-selection span:after {
    transform: rotate(-45deg);
}
.answer-selection input:checked + span {
    opacity: 1;
}
.answer-selection:hover input:not(:checked):not([readonly]) + span {
    opacity: .45;
}
.answer-variants-select-wrapper input[type="text"] {
    width: 120px;
    padding: 0.25rem;
    border: 1px solid var(--grey-border-color);
    border-radius: 0.25rem;
}
.answer-variants-select-wrapper input[type="text"]:focus {
    outline: none;
}
.answer-variants-select-wrapper .is-correct input[type="text"] {
    border-color: #0f0;
    background-color: rgba(0, 255, 0, .2);
}
.answer-variants-select-wrapper .is-incorrect input[type="text"] {
    border-color: #f00;
    background-color: rgba(255, 0, 0, .2);
}
.answer-inputs {
    padding-left: 15px;
}
.answer-inputs .correct-answer {
    padding: 0 15px;
}
.question-wrapper .answer-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.question-wrapper .answer-buttons-wrapper > div:first-child {
    min-width: 106px;
}
.question-wrapper .answer-buttons-wrapper button {
    height: 100%;
}
.zno-materials-page .zno-test .warning {
    color: #ff1100;
}
.test-result-wrapper .modal-content {
    width: 600px;
}
.test-result {
    padding: 30px;
    background-color: #B3ECF4;
    border-radius: .7rem;
}
.test-result .title,
.test-result .sub-title,
.test-result .timestamp {
    text-align: center;
    color: #00bfd8;
    padding: 10px 0 10px;
}
.test-result .timestamp {
    padding: 0;
}
.test-result .title {
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 4px;
}
.test-result .sub-title {
    font-size: 1.4rem;
    padding-bottom: 20px;
}
.test-result p {
    font-size: 1.25rem;
}
@media (max-width: 991px) {
    .zno-materials-page .question-wrapper {
        padding: 0;
    }
}