.synopsis-list-wrapper {
    transition: all .5s ease-out;
}
.synopsis-list-wrapper:not(.static) {
    position: fixed;
    right: 0;
    left: 0;
    top: 65px;
    background-color: #fff;
    z-index: 1031;
    padding-top: 20px;
}
.synopsis-list-wrapper.collapsed {
    right: -100%;
    left: 100%;
}
.synopsis-list {
    justify-content: center;
}
.synopsis-list-wrapper:not(.static) .synopsis-list {
    max-height: calc(100vh - 65px);
    overflow-y: auto;
}
.synopsis-page .synopsis-view {
    height: calc(100vh - 135px);
    user-select: none;
}
.synopsis-preloader-wrapper {
    min-height: calc(100vh - 400px);
    display: flex;
}
.synopsis-view svg {
    background-color: #fff;
}
.synopsis-view .viewer-layout-main {
    background-color: #eee;
}
.synopsis-list-toggler {
    cursor: pointer;
    outline: 0;
    padding: 7px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: .25rem;
    position: absolute;
    left: 20px;
    top: 3px;
}
.synopsis-list-toggler:focus {
    outline: 0;
}
.static .synopsis-list-toggler {
    display: none;
}
.synopsis-list-wrapper.collapsed .synopsis-list-toggler {
    left: -50px;
}
.toggler-bar {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 22px;
    height: 2px;
    vertical-align: middle;
    background-color: #555;
    content: "";
    outline: 0;
    border-radius: 1px;
}
.toggler-bar:nth-child(1) {
    top: 6px;
    transform: rotate(45deg);
}
.toggler-bar:not(:nth-child(1)) {
    margin-top: 4px;
}
.toggler-bar:nth-child(2) {
    opacity: 0;
}
.toggler-bar:nth-child(3) {
    bottom: 6px;
    transform: rotate(135deg);
}
.synopsis-list-wrapper.collapsed .toggler-bar:nth-child(1),
.synopsis-list-wrapper.collapsed .toggler-bar:nth-child(3) {
    top: 0;
    transform: rotate(0);
}
.synopsis-list-wrapper.collapsed .toggler-bar:nth-child(2) {
    opacity: 1;
}
.synopsis-title {
    padding: 3px;
}
.viewer-layout-toolbar .viewer-toolbar-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.viewer-toolbar-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 991px) {
    .synopsis-list-wrapper:not(.static):not(.collapsed) {
        left: 0;
    }
    .synopsis-list-wrapper:not(.collapsed) .synopsis-list-toggler {
        left: 20px;
    }
    .synopsis-preloader-wrapper {
        min-height: calc(100vh - 410px);
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .nav-open .synopsis-list-wrapper:not(.collapsed) {
        left: 260px;
    }
}
@media (max-width: 768px) {
    .synopsis-preloader-wrapper {
        min-height: calc(100vh - 480px);
    }
}
@media (max-width: 480px) {
    .synopsis-preloader-wrapper {
        min-height: calc(100vh - 545px);
    }
}