.modalWrapper {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    overflow-y: auto;
}
.modalContent {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    outline: 0;

    width: 500px;
    margin: auto;
    border-radius: .7rem;

    background-color: #fff;
    background-clip: padding-box;
}
.transparent {
    background-color: transparent;
}
@media (max-width: 991px) {
    .modalWrapper {
        padding: 65px 0 10px;
    }
}