.preloader {
    flex: 1 1 25%;
    margin: auto;
}
.skWave {
    width: 6em;
    height: 4em;
    margin: auto;
    text-align: center;
    font-size: 1em;
}
.skRect {
    margin: 0 3px;
    background-color: #fff;
    height: 100%;
    width: .5em;
    display: inline-block;
    -webkit-animation: sk-wave-stretch-delay 1.2s infinite ease-in-out;
    animation: sk-wave-stretch-delay 1.2s infinite ease-in-out;
}
.skRect1 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
}
.skRect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.skRect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.skRect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.skRect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.preloader.primary .skRect {
    background-color: var(--primary-color);
}

.preloader.secondary .skRect {
    background-color: var(--secondary-color);
}

@-webkit-keyframes sk-wave-stretch-delay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

@keyframes sk-wave-stretch-delay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}