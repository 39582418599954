:root {
  --primary-color: #00bfd8;
  --default-text-color: #555;
  --secondary-color: #ccc;
  --grey-border-color: rgba(0, 0, 0, .35);
}
html,
body {
  height: 100%;
  color: var(--default-text-color);
}
a {
  color: var(--default-text-color);
}
ul {
  padding: 0;
}
li {
  list-style: none;
}
img {
  max-width: 100%;
}
.link {
  cursor: pointer;
}
.bgc-primary {
  background-color: #009688;
}
.bgc-secondary {
  background-color: #FF9800;
}
.page-wrapper {
  min-height: calc(100vh - 295px);
  padding-top: 102px;
}
.header {
  margin-top: -102px;
}
.site-nav {
  padding: 10px;
  background-color: #fff;
}
.sidebar .sidebar-wrapper {
  width: auto;
  height: auto;
}
.site-nav li a.active {
  color: #17a2b8;
}
.form-control {
  border: 1px solid #c4d8dc;
}
.content.main-panel {
  min-height: 100vh;
}
.content > div:not(:last-child) {
  padding: 15px;
}
.content .top-menu a {
  padding: 0 7px;
}
.top-menu {
  margin-bottom: 15px;
  text-align: center;
}
.top-menu > ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 60px;
  background-color: #fff;
  padding: 7px;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
}
.static-content {
  padding: 3% 5%;
  text-align: justify;
}
.settings-page .card {
  background-color: rgba(255, 255, 255, .7);
}
.contact-form {
  position: relative;
}
.contact-form-overlay {
  position: absolute;
  top: -7px;
  bottom: -7px;
  left: -7px;
  right: -7px;
  background-color: rgba(255, 255, 255, .75);
  border-radius: 10px;
  box-shadow: 0 0 5px 3px rgba(255, 255, 255, .75);
  display: flex;
  text-align: center;
}
.contact-form-overlay > * {
  margin: auto;
}
.grecaptcha-badge {
  opacity: 0;
  z-index: -1;
}
.btn-outline-primary {
  color: #00bfd8;
  border-color: #00bfd8;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #00bfd8;
  border-color: #00bfd8;
}
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: #00a0d3;
  border-color: #00a0d3;
}
.required-mark {
  color: #f00;
}
@media (min-width: 1087px) and (max-width: 1400px) {
  .page-wrapper {
    padding-top: 122px;
  }
}
@media (min-width: 992px) and (max-width: 1086px) {
  .page-wrapper {
    padding-top: 148px;
  }
}
@media (max-width: 991px) {
  .page-wrapper {
    min-height: calc(100vh - 340px);
  }
}
@media (max-width: 768px) {
  .page-wrapper {
    min-height: calc(100vh - 410px);
  }
}
@media (max-width: 480px) {
  .page-wrapper {
    min-height: calc(100vh - 445px);
  }
}