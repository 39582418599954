body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
.h1 {
  font-size: 1.45rem;
}

h2,
.h2 {
  font-size: 1.35rem;
}

h3,
.h3 {
  font-size: 1.25rem;
}

h4,
.h4 {
  font-size: 1.15rem;
}

h5,
.h5 {
  font-size: 1.05rem;
}

h6,
.h6 {
  font-size: 1rem;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

.card {
  margin-bottom: 0;
}
.card-chart .card-header.card-header-icon i {
  font-size: 36px;
  line-height: 50px;
  width: 50px;
  height: 50px;
  text-align: center;
}