.zno-materials-page .list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
}
.zno-materials-page .category-list {
    padding: 0 45px;
}
.zno-materials-page .category .title {
    position: relative;
    max-width: 40%;
    color: var(--primary-color);
}
.zno-materials-page .category .title:before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    right: 0;
    height: 2px;
    background: var(--primary-color);
    z-index: -1;
}
.zno-materials-page .category .title > span {
    background: white;
    padding: 0 15px;
    margin-left: 20px;
}
.zno-materials-page .list-element {
    margin: 0 5px 5px;
    background-color: #fff;
    text-align: center;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, .35);
    box-shadow: 5px 5px 15px #DFF7FB;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.zno-materials-page .list-element.current {
    background-color: #90f0f0;
}
.zno-materials-page .category .list .list-element-wrapper {
    width: 150px;
    padding: 0 5px;
    border-radius: 7px;
    color: #ccc;
}
.zno-materials-page .category .list-element.allowed .list-element-wrapper {
    color: var(--default-text-color);
}
.zno-materials-page .category .list-element.active .list-element-wrapper {
    color: var(--primary-color);
}
.zno-materials-page .category .list-element.active {
    border-color: var(--primary-color);
}
@media (max-width: 479px) {
    .zno-materials-page .list {
        padding: 0;
    }
    .zno-materials-page .category-list {
        padding-left: 5px;
        padding-right: 5px;
    }
    .zno-materials-page .category .title {
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 375px) {
    .zno-materials-page .category .list .list-element-wrapper {
        width: 128px;
    }
}
@media (max-width: 992px) {
    .zno-materials-page .category .title {
        max-width: 420px;
    }
}