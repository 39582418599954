.listElement {
    margin: 0 5px 5px;
    background-color: #fff;
    text-align: center;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, .35);
    box-shadow: 5px 5px 15px #dff7fb;
    align-items: center;
}
.listElement.active {
    border-color: var(--primary-color);
}
.listElement a {
    display: flex;
    height: 100%;
    text-decoration: none;
    padding: 5px;
}
.cardListElementWrapper {
    width: 150px;
    margin: auto;
    border-radius: 7px;
    color: #ccc;
}
.active .cardListElementWrapper {
    color: var(--primary-color);
}
.allowed .cardListElementWrapper {
    color: var(--default-text-color);
}
.denied .cardListElementWrapper {
    color: #ccc;
}
.highlighted {
    border-color: var(--primary-color);
    box-shadow: inset 0 0 3px var(--primary-color), 5px 5px 15px #dff7fb;
    background-color: #dff7fb;
}