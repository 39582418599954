.wrapper {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.error {
    background-color: rgba(255, 0, 0, 0.3);
    margin: auto;
    padding: 10px;
    border-radius: 10px;
    color: #900;
}