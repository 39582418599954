.dropdown-toggle {
    cursor: default;
}
.dropdown-toggle::after {
    display: none;
}
.nav-item,
.dropdown-menu {
    text-align: center;
}
/*
.nav-item.dropdown:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}*/
