.login-form button:disabled,
.registration-form button:disabled {
    border-color: #ccc;
    background-color: #fff;
    color: #ccc;
}
.login-form button .sk-wave,
.registration-form button .sk-wave {
    height: 45px;
}
.registration-form h2,
.login-form h2 {
    text-align: center;
    color: var(--primary-color);
}

@media (min-width: 820px) {
    .modal-root .registration-form-wrapper {
        width: 800px;
    }
    .registration-form .form-field-group {
        display: flex;
    }
    .form-field-group .form-group {
        width: 50%;
    }
    .form-field-group .form-group:first-child {
        margin-right: 5px;
    }
    .form-field-group .form-group:last-child {
        margin-left: 5px;
    }
}